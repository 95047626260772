// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: about.text,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "book/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img2: file(relativePath: { eq: "book/img2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img3: file(relativePath: { eq: "book/img3.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img4: file(relativePath: { eq: "book/img4.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img5: file(relativePath: { eq: "book/img5.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img6: file(relativePath: { eq: "book/img6.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img7: file(relativePath: { eq: "book/img7.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <AboutPageSchema />
    <ContactPageSchema />
    <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
      <h1>About this offering</h1>
      <p>{about.text}</p>
      <p>
        Attribution: Tiny Tales by Laura Gibbs
        <br />
        <Link to="https://100Words.LauraGibbs.net">
          Visit 100Words.LauraGibbs.net for more →
        </Link>
      </p>
      <h3>Tiny tales from Aesop</h3>
      <p>
        Aesop was a legendary storyteller of ancient Greece, and the stories
        called 'Aesop's fables' have been going strong for three thousand years.
        This book contains a selection of classical, medieval, Renaissance, and
        modern Aesop's fables, ranging from the ancient Roman poet Phaedrus to
        the 18th-century neo-Latin poet Desbillons. You will find famous fables
        here such as 'The Lion's Share' and 'The Boy Who Cried Wolf,' plus many
        not-so-famous fables about animals, about people, and about the gods and
        goddesses too. The fables included here represent only a small fraction
        of the Aesopic fable tradition.
      </p>
      <h3>Tiny Tales from Africa: The Animals (Volume 1)</h3>
      <p>
        This is the first in what I hope will be a long series of books about
        African animal tales. You will meet tricksters here like Rabbit
        (ancestor of Brer Rabbit and Tio Conejo), along with Tortoise, Spider,
        and more. There are “pourquoi” stories about why Bat flies at night and
        why Lemur’s eyes are big. There are also stories of magical
        transformations, like the woman who became a lion. The stories come from
        many different African traditions; see the list of sources in the back
        of the book.
      </p>
      <h3>Tiny Tales from Africa: The Animals (Volume 2)</h3>
      <p>
        This is the second in a series of books featuring African animal tales.
        You’ll find many tricksters here: there’s Rabbit (the ancestor of Brer
        Rabbit and Tio Conejo), along with Tortoise, Spider, and also
        Mouse-Deer, Neotragus pygmaeus, an African antelope barely a foot tall.
        There are “pourquoi” stories like how Warthog got his tusks and how Crab
        got his shell, plus stories of magic and supernatural adventure. These
        tales come from many different African traditions; see the list of
        sources in the back of the book.
      </p>
      <h3>Tiny tales from India</h3>
      <p>
        This book opens with traditional folktales from the Panchatantra, the
        Hitopadesha, and the Katha-Sarit-Sagara ('Story-Stream-Ocean'), plus
        Jataka tales of the Buddha's past lives. You will also find stories of
        the Indian gods and goddesses, plus parables from Ramakrishna, who was
        both a sadhu (holy man) and storyteller. The book closes with anecdotes
        about the legendary jesters Tenalirama from the court of Krishnadevaraya
        in southern India and Birbal from the court of Akbar in the north. The
        two hundred stories in this book represent only a tiny fraction of the
        Indian storytelling tradition.
      </p>
      <h3>Tiny tales from the Mahabharata</h3>
      <p>
        The Mahabharata tells of the war between the Pandavas and Kauravas,
        cousins who conceived a deadly hatred for one another. The epic begins
        with the story of King Shantanu, the Pandavas' great-grandfather, and
        ends with King Janamejaya, the Pandavas' great-grandson. You will meet
        Draupadi, the heroine who is married to all five of the Pandava
        brothers, and you will also meet Krishna, the human avatar of the god
        Vishnu who sides with the Pandavas in the war.
      </p>
      <h3>Tiny tales from the Ramayana</h3>
      <p>
        The Ramayana, "Rama's Journey," is the story of Rama and his wife Sita,
        avatars of the devas (gods) Vishnu and Lakshmi. Rama was born to
        vanquish Ravana, king of the fierce rakshasas (demons). There are many
        versions of the Ramayana in India, and Rama's adventures are also famous
        throughout South Asia. In this book, you'll see Rama's story revealed in
        a series of vignettes from the ancient Sanskrit Ramayana composed by the
        poet Valmiki, along with other Ramayanas from India and beyond.
      </p>
      <h3>Tiny tales from the Sufis</h3>
      <p>
        The Sufis are spiritual seekers in the Islamic tradition, and much Sufi
        wisdom is conveyed in story form. In this book you will find stories
        from the Persian poet Rumi and other Sufi saints, along with stories
        from modern Sufi teachers such as Inayat Khan. You will meet the mad
        lover Majnun in these pages, and also the wise fool Nasruddin. Sufi
        stories aim to jolt you into a new awareness of reality, and, like
        parables, the stories sometimes conceal their deeper meaning. Look
        beneath the surface; you might realize the story is about you.
      </p>
      <h3>Tiny tales of Anansi</h3>
      <p>
        Anansi the Spider is an African trickster. Enslaved African storytellers
        brought Anansi's stories to the Americas, and the Anansi stories in this
        book come from the Caribbean: from Jamaica, Antigua, Grenada, and more.
        Anansi's main enemies are Tiger and Lion (think Caribbean big cats, like
        ocelots and mountain lions). Anansi has friends too, like Dog and a
        character named Tacoomah. But beware: it's dangerous to be Anansi's
        friend. Anansi will trick his friends or even his family to get what he
        wants. He is cunning and also cruel; ingenious, but greedy. Musician,
        magician, fearless, reckless: Anansi is full of surprises.
      </p>
      <h3>Tiny tales of Nasruddin</h3>
      <p>
        Stories of Nasruddin date back to 13th-century Turkey. Legend tells us
        Nasruddin was born in Sivrihisar in central Anatolia, and a tomb in
        Akshehir bears his name. Nasruddin's stories spread throughout the
        Middle East, South Asia, and Central Asia, and UNESCO declared 1996-1997
        'The International Year of Nasruddin.' As you read these stories, you
        will see that Nasruddin is sometimes wise, sometimes foolish, and
        sometimes both at once. The 200 stories in this book are just a small
        fraction of the Nasruddin story tradition.
      </p>
      <h2>Open-access format</h2>
      <p>
        <i>{about.altTitle}</i>&nbsp;is adapted from the works of Laura Gibbs
        and is made available in this open-acces format as part of an emerging
        method of publishing by Prisma Books. The idea is to deliver the high
        level of writing and design quality that you would find in a print book,
        and to use advanced web technologies for publishing. This is the
        open-access format.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about the open-access format →
        </Link>
      </p>
      <h2 id="on-gift-economy">License</h2>
      <p>
        Creative Commons. This work is released with a Creative Commons license:
        CC BY-NC-SA 4.0. That means you can remix and reuse individual stories
        or the contents of the whole book with attribution for non-commercial
        purposes, provided that you release your work with the same license.
        Find out more.
      </p>
      <p>Attribution: Tiny Tales by Laura Gibbs.</p>
      <p>
        <Link to="https://100Words.LauraGibbs.net">
          Visit 100Words.LauraGibbs.net for more →
        </Link>
      </p>
      <h2>Prisma Books, the publisher</h2>
      <p>
        Since 1988 Prisma Books of Auroville publishes good old-fashioned,
        beautifully curated books. Prisma Books offers full publishing services
        in design, editorial, printing, artwork, illustrations, photography,
        digital media, exhibition and curatorship.
      </p>
      <p>
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
